<template>
  <a
    v-if="isExternal && typeof to === 'string'"
    v-bind="$attrs"
    :href="to"
    :target="target"
    :class="[variantClasses, { underline: props.underline }]">
    <slot />
  </a>
  <NuxtLink v-else v-bind="$props" :class="[variantClasses, { underline: props.underline }]" :target="target">
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { RouteLocationRaw } from "vue-router";

import { isExternalLink } from "@/util/isExternalLink";

const props = withDefaults(
  defineProps<{
    target?: "_blank" | "_self" | "_parent" | "_top";
    to: RouteLocationRaw;
    variant?: "link" | "link-danger" | "plain" | "small";
    underline?: boolean;
  }>(),
  {
    underline: true,
    variant: "link",
  },
);

const target = computed(() => (isExternal.value === true ? "_blank" : props.target));

const variantClasses = computed(() => {
  switch (props.variant) {
    case "link":
      return "visited:text-gray-900 hover:text-yellow-700 disabled:opacity-50 transition duration-300 ease-in-out";
    case "link-danger":
      return "text-red-600 visited:text-gray-900 hover:text-yellow-700 disabled:opacity-50 transition duration-300 ease-in-out";
    case "small":
      return "text-sm font-normal";
    case "plain":
    default:
      return "";
  }
});

const isExternal = computed(() => isExternalLink(props.to));
</script>

<style scoped></style>
