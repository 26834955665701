import { default as _91_46_46_46slug_930QQqQl6ppoMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/[...slug].vue?macro=true";
import { default as indexb27mErcLaOMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/[departmentZip]-wien/[healthProfessional]/index.vue?macro=true";
import { default as indexTcVguAX07qMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/[departmentZip]-wien/index.vue?macro=true";
import { default as _91_46_46_46slug_93z7rmLIzRLXMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/blog/[...slug].vue?macro=true";
import { default as indexgMEv9SbT8hMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93FQJMcxE2LKMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/blog/kategorie/[...slug].vue?macro=true";
import { default as indextIT61n64WUMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/[department]/[service]/[location]/[doctor]/index.vue?macro=true";
import { default as indextMqCwsWuIkMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/[department]/[service]/[location]/index.vue?macro=true";
import { default as indexjBJommiQtVMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/[department]/[service]/index.vue?macro=true";
import { default as indexoxzWXvlMaEMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/[department]/index.vue?macro=true";
import { default as index0AbYGL3xQCMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/[doctor]/index.vue?macro=true";
import { default as indexDKtiNx3OndMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/index.vue?macro=true";
import { default as indexyafVOvvIPuMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/index.vue?macro=true";
import { default as index44SvVHMkorMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/vorsorgemedizin/index.vue?macro=true";
import { default as fachrichtungenyUE5r6LU8OMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtungen.vue?macro=true";
import { default as indexrvLG7IV2ktMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/index.vue?macro=true";
import { default as _91slug_937qaPtUJLbDMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/linktree/[slug].vue?macro=true";
import { default as _91slug_93IxEnMxiTKCMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/team/[slug].vue?macro=true";
import { default as indexNGQLFKVRbNMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/team/index.vue?macro=true";
import { default as vielen_45dankSLhuSlJNKxMeta } from "/home/runner/work/jupiter/jupiter/frontend/pages/vielen-dank.vue?macro=true";
import { default as _91_46_46_46slug_93tLe8Zr7zkiMeta } from "~/pages/[...slug].vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93HqBsLitTXBMeta } from "~/pages/blog/[...slug].vue?macro=true";
import { default as indexwcDEFiGKxqMeta } from "~/pages/blog/index.vue?macro=true";
import { default as component_45stubrP2ZZebGQcMeta } from "/home/runner/work/jupiter/jupiter/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrP2ZZebGQc } from "/home/runner/work/jupiter/jupiter/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "departmentZip-wien-healthProfessional",
    path: "/:departmentZip()-wien/:healthProfessional()",
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/[departmentZip]-wien/[healthProfessional]/index.vue").then(m => m.default || m)
  },
  {
    name: "departmentZip-wien",
    path: "/:departmentZip()-wien",
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/[departmentZip]-wien/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexgMEv9SbT8hMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-kategorie-slug",
    path: "/blog/kategorie/:slug(.*)*",
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/blog/kategorie/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "fachrichtung-department-service-location-doctor",
    path: "/fachrichtung/:department()/:service()/:location()/:doctor()",
    meta: indextIT61n64WUMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/[department]/[service]/[location]/[doctor]/index.vue").then(m => m.default || m)
  },
  {
    name: "fachrichtung-department-service-location",
    path: "/fachrichtung/:department()/:service()/:location()",
    meta: indextMqCwsWuIkMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/[department]/[service]/[location]/index.vue").then(m => m.default || m)
  },
  {
    name: "fachrichtung-department-service",
    path: "/fachrichtung/:department()/:service()",
    meta: indexjBJommiQtVMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/[department]/[service]/index.vue").then(m => m.default || m)
  },
  {
    name: "fachrichtung-department",
    path: "/fachrichtung/:department()",
    meta: indexoxzWXvlMaEMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/[department]/index.vue").then(m => m.default || m)
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup-service-doctor",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()/:service()/:doctor()",
    meta: index0AbYGL3xQCMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/[doctor]/index.vue").then(m => m.default || m)
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup-service",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()/:service()",
    meta: indexDKtiNx3OndMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/index.vue").then(m => m.default || m)
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()",
    meta: indexyafVOvvIPuMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/index.vue").then(m => m.default || m)
  },
  {
    name: "fachrichtung-vorsorgemedizin",
    path: "/fachrichtung/vorsorgemedizin",
    meta: index44SvVHMkorMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtung/vorsorgemedizin/index.vue").then(m => m.default || m)
  },
  {
    name: "fachrichtungen",
    path: "/fachrichtungen",
    meta: fachrichtungenyUE5r6LU8OMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/fachrichtungen.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "linktree-slug",
    path: "/linktree/:slug()",
    meta: _91slug_937qaPtUJLbDMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/linktree/[slug].vue").then(m => m.default || m)
  },
  {
    name: "team-slug",
    path: "/team/:slug()",
    meta: _91slug_93IxEnMxiTKCMeta || {},
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/team/[slug].vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: "vielen-dank",
    path: "/vielen-dank",
    component: () => import("/home/runner/work/jupiter/jupiter/frontend/pages/vielen-dank.vue").then(m => m.default || m)
  },
  {
    name: "kinderarzt-1020-wien",
    path: "/kinderarzt-1020-wien",
    component: () => import("~/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "osteopathie-1020-wien",
    path: "/osteopathie-1020-wien",
    component: () => import("~/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "psychotherapie-1180-wien",
    path: "/psychotherapie-1180-wien",
    component: () => import("~/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "psychotherapie-1020-wien",
    path: "/psychotherapie-1020-wien",
    component: () => import("~/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "physiotherapie-1180-wien",
    path: "/physiotherapie-1180-wien",
    component: () => import("~/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "physiotherapie-1020-wien",
    path: "/physiotherapie-1020-wien",
    component: () => import("~/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "agb",
    path: "/agb",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "fuer-aerzte-therapeuten",
    path: "/fuer-aerzte-therapeuten",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "fuer-unternehmen",
    path: "/fuer-unternehmen",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "generali-rundum-gesund",
    path: "/generali-rundum-gesund",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "haeufige-fragen",
    path: "/haeufige-fragen",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mitgliedschaft",
    path: "/mitgliedschaft",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ueber-uns",
    path: "/ueber-uns",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "uniqa-partnerzentrum",
    path: "/uniqa-partnerzentrum",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "uniqa-vitalcheck",
    path: "/uniqa-vitalcheck",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "uniqa-vorsorgeprogramm",
    path: "/uniqa-vorsorgeprogramm",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "vorsorge",
    path: "/vorsorge",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "wiener-staedtische",
    path: "/wiener-staedtische",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-preview-slug",
    path: "/blog/preview/:slug",
    component: () => import("~/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-index-page",
    path: "/blog/:page(\\d+)",
    meta: indexwcDEFiGKxqMeta || {},
    component: () => import("~/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubrP2ZZebGQcMeta?.name,
    path: "/blog/1",
    component: component_45stubrP2ZZebGQc
  }
]