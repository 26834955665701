<template>
  <AppLink v-if="to" :to="to" :variant="linkVariant" :underline="false" :class="[baseClasses, variantClasses]">
    <slot />
  </AppLink>
  <button v-else type="button" :disabled="disabled" :class="[baseClasses, variantClasses]">
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { RouteLocationRaw } from "vue-router";

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    variant?: "primary" | "secondary" | "icon" | "outline" | "link" | "small";
    to?: RouteLocationRaw;
  }>(),
  {
    disabled: false,
    to: undefined,
    variant: "primary",
  },
);

const linkVariant = computed(() => {
  return props.variant === "link" ? "link" : "plain";
});

const variantClasses = computed(() => {
  switch (props.variant) {
    case "primary":
      return "justify-center py-3 px-6 text-sm font-medium bg-yellow text-gray-900 enabled:hover:bg-yellow focus:ring-yellow-700 active:ring-yellow-400 enabled:hover:bg-yellow-300 gap-2";
    case "secondary":
      return "justify-center py-3 px-5 text-sm font-medium bg-gray-900 text-white enabled:hover:bg-yellow enabled:hover:text-gray-900 focus:ring-yellow active:bg-gray-800 active:ring-0";
    case "outline":
      return "justify-center py-3 px-5 text-sm font-medium border-2 border-gray-900 bg-transparent text-gray-900 ring-0 enabled:hover:border-gray-100 enabled:hover:bg-gray-100 focus:bg-gray-100 active:border-gray-100 active:bg-gray-700 active:text-white";
    case "icon":
      return "justify-center w-auto bg-gray-100 p-3 enabled:hover:bg-gray-200 focus:bg-yellow-200 active:bg-yellow-200 self-center";
    case "link":
      return "justify-start focus:ring-yellow-700";
    case "small":
      return "bg-brick px-4 py-1 text-xs font-medium";
    default:
      return "";
  }
});
const baseClasses =
  "flex items-center rounded-full focus:outline-none focus:ring-2 ring-offset-2 disabled:opacity-30 transition duration-300 ease-in-out";
</script>

<style scoped></style>
