import { defineStore } from "pinia";
import type { Global } from "~/types/strapi/api/global/content-types/global/global";
import type { Payload } from "~/types/strapi/common/schemas-to-ts/Payload";

export const useGlobalStore = defineStore("globalStore", () => {
  const globalData = ref<Payload<Global> | null>(null);
  const pending = ref(false);

  async function fetchGlobal(): Promise<void> {
    if (globalData.value !== null || pending.value === true) {
      return;
    }

    pending.value = true;
    const { data } = await useApiFetch<Payload<Global>>("/api/global?populate=deep");
    globalData.value = data.value;
    pending.value = false;
  }

  return {
    fetchGlobal,
    globalData,
    pending,
  };
});
